<template>
  <div class="body">
    <!-- 头部 -->
    <top></top>
    <div class="contentpay">
      <!-- 支付金额 -->
      <div class="sum_box">
        <div class="sum">
          <!-- 支付金额 -->
          <div class="sum_font">
            <h3>支付金额(元)</h3>
          </div>
          <!-- 钱数 -->
          <div class="money_amount">
            <p>
              <span>{{ pageb }}:¥</span>{{ pagec }}元
            </p>
          </div>
          <!-- 服务协议 -->
          <div class="agreement">
            <p>
              支付即同意
              <span style="color: #F62959;" @click="fuwu">
                《VIP服务协议》</span
              >
            </p>
          </div>
        </div>
      </div>
      <!-- 微信支付 -->
      <div class="procedures_box">
        <div class="procedures">
          <!-- 使用微信 -->
          <div class="procedures_left">
            <div class="">
              <!-- 二维码 -->
              <div class="QR_code_box">
                <div class="QR_code">
                  <img :src="paged" alt="" />
                </div>
              </div>
              <!-- 二维码下面的文字 -->
              <div class="QR_code_font">
                <p><img src="../assets/home/50.png" />使用微信扫码支付</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <bottom></bottom>
  </div>
</template>
<script>
    import Qs from "qs";
    export default {
        data() {
            return {
                pageb: "",
                pagec: "",
                paged: "",
                moneyaaa: [],
                uiduid: "",
                userfun: "",
                dingdan: "",
                valuea: [],
            };
        },
        created() {
            // 购买类型
            this.pageb = this.$route.params.title;
            // 购买金额
            this.pagec = this.$route.params.price;
            // 付款码
            this.paged = this.$route.params.pricd;

            // 订单号
            this.dingdan = this.$route.params.ordernumber;
            console.log(this.dingdan);

            //从sessionStorage中获取用户的类型
            var infostr = sessionStorage.getItem("key");
            var keyaaa = JSON.parse(infostr);
            this.valuea = keyaaa;
        },
        mounted() {
            this.userfun = setInterval(() => {
                this.callbackfunction();
            }, 1000);
        },
        //页面关闭时清除定时器
        beforeDestroy() {
            clearInterval(this.userfun);
        },
        methods: {
            // 支付回调
            async callbackfunction() {
                var that = this;
                this.$axios({
                        method: "post",
                        url: "/api/shopmini/wap_Dingdanstatus.html",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: Qs.stringify({
                            dingdan: that.dingdan,
                        }),
                    })
                    .then(function(res) {
                        if (res.data.static == 1) {
                            that.$message({
                                message: "支付成功",
                                type: "success",
                            });
                            that.valuea.class = res.data.user.class;
                            that.valuea.membertime = res.data.user.membertime;
                            let newKey = JSON.stringify(that.valuea);
                            sessionStorage.setItem("key", newKey);
                            clearInterval(that.userfun);
                            setTimeout(() => {
                                that.$router.push({
                                    path: "/Personal",
                                });
                            }, 3000);
                        } else {
                            console.log("失败");
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            },
            // vip服务协议按钮
            fuwu() {
                this.$message("服务协议");
            },
        },
    };
</script>
<style scoped>
    .body {
        background-color: #fafafa;
    }
    /* 内容 */
    
    .contentpay {
        height: 565px;
        padding-top: 70px;
    }
    /* 支付金额 */
    
    .sum_box {
        width: 100%;
        min-width: 1200px;
        padding-top: 55px;
    }
    
    .sum {
        width: 1200px;
        margin: auto;
    }
    /* 支付金额 */
    
    .sum_font {
        width: 100%;
        text-align: center;
        font-size: 24px;
        line-height: 40px;
        color: #020202;
    }
    /* 钱数 */
    
    .money_amount {
        line-height: 70px;
        text-align: center;
    }
    
    .money_amount p {
        color: #f62959;
        font-size: 40px;
        font-weight: bold;
    }
    
    .money_amount span {
        font-size: 24px;
    }
    /* 支付协议 */
    
    .agreement {
        color: #999999;
        text-align: center;
        cursor: pointer;
    }
    /* 支付方式 */
    
    .procedures_box {
        width: 100%;
        min-width: 1200px;
        margin-top: 40px;
    }
    
    .procedures {
        width: 1200px;
        margin: auto;
        display: flex;
    }
    
    .procedures_left {
        /* background-color: red; */
        margin: 0 auto;
    }
    
    .procedures>div {
        /* width: 44%; */
        display: flex;
    }
    
    .procedures>div:nth-of-type(2) {
        margin-left: 12%;
    }
    /* 二维码 */
    
    .procedures>div:nth-of-type(1) {
        justify-content: flex-end;
    }
    
    .QR_code_box {
        width: 220px;
        height: 220px;
        border: 1px #f62959 solid;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .QR_code {
        width: 196px;
        height: 196px;
        box-sizing: border-box;
    }
    
    .QR_code img {
        width: 100%;
        height: 100%;
        vertical-align: top;
    }
    /* 支付下面的文字 */
    
    .QR_code_font p {
        text-align: center;
        line-height: 50px;
        color: #333333;
        font-size: 18px;
        font-weight: bold;
    }
    
    .QR_code_font img {
        width: 30px;
        vertical-align: middle;
        margin-right: 4px;
    }
</style>